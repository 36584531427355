import { AdminAccountNavItems } from 'common/enums/app/i-admin.enum';
import { LandlordAccountNavItems } from 'common/enums/app/i-landlord.enum';
import { RenterAccountNavItems } from 'common/enums/app/i-rent.enum';
import { AdminAccountSideBarItems } from 'common/enums/enums';

export const renterDropdownTitle: Record<RenterAccountNavItems, string> = {
  [RenterAccountNavItems.ProfileInformation]: 'Profile Information',
  [RenterAccountNavItems.UpcomingTours]: 'Upcoming Tours',
  [RenterAccountNavItems.TourHistory]: 'Tour History',
  [RenterAccountNavItems.Offers]: 'Offers',
  [RenterAccountNavItems.SavedSearch]: 'Saved Search',
  // [RenterAccountNavItems.Favorites]: 'Favorites',
  [RenterAccountNavItems.Security]: 'Account Security',
  // [RenterAccountNavItems.Referral]: 'Referral',
};

export const sideBarRenterMenuLink: Record<RenterAccountNavItems, string> = {
  [RenterAccountNavItems.ProfileInformation]: 'info',
  [RenterAccountNavItems.UpcomingTours]: 'tours/upcoming',
  [RenterAccountNavItems.TourHistory]: 'tours/history',
  // [RenterAccountNavItems.Favorites]: 'favorites',
  [RenterAccountNavItems.Offers]: 'offers',
  [RenterAccountNavItems.SavedSearch]: 'saved-search',
  [RenterAccountNavItems.Security]: 'security',
  // [RenterAccountNavItems.Referral]: 'referral',
};

export const getLoggedUserDropdownMenu = (menuId: RenterAccountNavItems) => ({
  id: menuId,
  title: renterDropdownTitle[menuId],
  link: `i-rent/profile/${sideBarRenterMenuLink[menuId]}`,
  notification: menuId === RenterAccountNavItems.SavedSearch,
});

export const renterDropdownMenuList = Object.values(RenterAccountNavItems).map(getLoggedUserDropdownMenu);

export const landlordDropdownTitle: Record<LandlordAccountNavItems, string> = {
  [LandlordAccountNavItems.ListProperty]: 'List A Property',
  [LandlordAccountNavItems.ProfileInformation]: 'Profile Information',
  [LandlordAccountNavItems.Listings]: 'Listings',
  [LandlordAccountNavItems.Threshholds]: 'Thresholds',
  [LandlordAccountNavItems.TourSchedule]: 'Tour Schedule',
  [LandlordAccountNavItems.Offers]: 'Offers',
  [LandlordAccountNavItems.AccessAccounts]: 'Access Accounts',
  [LandlordAccountNavItems.LeaseDocuments]: 'Lease Documents',
  [LandlordAccountNavItems.Security]: 'Account Security',
};

export const adminDropdownTitle: Record<AdminAccountSideBarItems, string> = {
  [AdminAccountNavItems.ProfileInformation]: 'Profile Information',
  [AdminAccountNavItems.Listings]: 'Listings',
  [AdminAccountNavItems.TourSchedule]: 'Tour Schedule',
  [AdminAccountNavItems.Offers]: 'Offers',
  [AdminAccountNavItems.Commissions]: 'Commissions',
  // [AdminAccountSideBarItems.Gifts]: 'Gifts',
  // [AdminAccountSideBarItems.Referral]: 'Referral',
  [AdminAccountSideBarItems.Security]: 'Account Security',
  [AdminAccountSideBarItems.Feedbacks]: 'Feedbacks'
};

export const sideBarLandlordMenuLink: Record<LandlordAccountNavItems, string> = {
  [LandlordAccountNavItems.ListProperty]: 'i-landlord/properties',
  [LandlordAccountNavItems.ProfileInformation]: 'i-landlord/profile/info',
  [LandlordAccountNavItems.Listings]: 'i-landlord/profile/listing',
  [LandlordAccountNavItems.Threshholds]: 'i-landlord/profile/thresholds',
  [LandlordAccountNavItems.TourSchedule]: 'i-landlord/profile/schedule',
  [LandlordAccountNavItems.Offers]: 'i-landlord/profile/offers',
  [LandlordAccountNavItems.AccessAccounts]: 'i-landlord/profile/access-accounts',
  [LandlordAccountNavItems.LeaseDocuments]: 'i-landlord/profile/lease-documents',
  [LandlordAccountNavItems.Security]: 'i-landlord/profile/security',
};

export const sideBarAdminMenuLink: Record<AdminAccountSideBarItems, string> = {
  [AdminAccountNavItems.ProfileInformation]: 'info',
  [AdminAccountNavItems.Listings]: 'listing',
  [AdminAccountNavItems.TourSchedule]: 'schedule',
  [AdminAccountNavItems.Offers]: 'offers',
  [AdminAccountSideBarItems.Commissions]: 'commissions',
  // [AdminAccountSideBarItems.Gifts]: 'gifts',
  // [AdminAccountSideBarItems.Referral]: 'referral',
  [AdminAccountSideBarItems.Security]: 'security',
  [AdminAccountSideBarItems.Feedbacks]: 'feedbacks'
};

export const getLandlordDropdownMenu = (menuId: LandlordAccountNavItems) => ({
  id: menuId,
  title: landlordDropdownTitle[menuId],
  link: sideBarLandlordMenuLink[menuId],
});

export const getAdminDropdownMenu = (menuId: AdminAccountSideBarItems) => ({
  id: menuId,
  title: adminDropdownTitle[menuId],
  link: `admin/profile/${sideBarAdminMenuLink[menuId]}`,
});

export const landlordDropdownMenuList = Object.values(LandlordAccountNavItems).map(getLandlordDropdownMenu);
export const adminDropdownMenuList = Object.values(AdminAccountSideBarItems).map(getAdminDropdownMenu);

import { UndefinedInitialDataOptions, useMutation, UseMutationOptions, useQuery } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { QueryKey } from "common/enums/query-key";
import { BackendError } from "common/types/errors/backendError";
import { ApplicantSignature, MasterAgreementApplicant, MasterAgreementInfo } from "legacy-pages/renter/profile/renter-profile/master-agreement/master-agreement.type";
import { getMasterAgreement, getMasterAgreementApplicant, signMasterAgreement } from "services/api/lease-documents";
import { Notification } from 'services/notification.service';
import { useQueryCustomErrorWithRedirectHandler } from "./use-query-custom-error-handler";

export const useGetMasterAgreement = (
  applicationId: string,
  options?: Omit<UndefinedInitialDataOptions<MasterAgreementInfo | undefined, Error, MasterAgreementInfo, string[]>, 'queryFn' | 'queryKey'>,
) => {
  return useQuery({
    queryKey: [QueryKey.MasterAgreement, applicationId],
    queryFn: () => getMasterAgreement(applicationId),
    ...options,
  });
}

export function useSignMasterAgreement(options?: Omit<UseMutationOptions<void, AxiosError<BackendError>, ApplicantSignature, unknown>, 'mutationFn'>) {
  return useMutation({
    ...options,
    mutationFn: signMasterAgreement,
    onError: (e) => {
      const notification = new Notification();
      notification.error(e.message || 'Sorry, an error occurred');
    },
    onSuccess: (data, variables, context) => {
      const notification = new Notification();
      notification.success('The master agreement has been signed.');
    }
  });
}

export const useGetMasterAgreementApplicant = (
  applicantId: string,
  options?: Omit<UndefinedInitialDataOptions<MasterAgreementApplicant | undefined, Error, MasterAgreementApplicant, string[]>, 'queryFn' | 'queryKey'>,
) => {
  const { onError } = useQueryCustomErrorWithRedirectHandler();
  return useQuery({
    queryKey: [QueryKey.MasterAgreementApplicant, applicantId],
    queryFn: () => getMasterAgreementApplicant(applicantId).catch(onError),
    enabled: !!applicantId,
    ...options,
  });
}
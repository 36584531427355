export enum OfferStatusDto {
  PendingPayment = 'PendingPayment',
  PreApplicationPaid = 'PreApplicationPaid',
  Accept = 'Accept',
  Reject = 'Reject',
  Paid = 'Paid',
  Cancel = 'Cancel',
  // Expired = 'Expire',
  PaymentInProcess = 'PaymentInProcess',
  PreApplicationAccepted = 'PreApplicationAccepted',
  IncompleteOffer = 'IncompleteOffer',
  PaymentFailed = 'PaymentFailed',
  PreApplicationRejected = 'PreApplicationRejected',
  DifferentPreApplicationAccepted = 'DifferentPreApplicationAccepted',
  BackgroundCheckRequested = 'BackgroundCheckRequested',
  BackgroundCheckInitiated = 'BackgroundCheckInitiated',
  BackgroundCheckCancelled = 'BackgroundCheckCancelled',
  BackgroundCheckIgnored = 'BackgroundCheckIgnored',
  BackgroundCheckCompleted = 'BackgroundCheckCompleted',
  CreditCheckCompleted = 'CreditCheckCompleted',
  LandlordAcceptedBackgroundCheck = 'LandlordAcceptedBackgroundCheck',
  LandlordRejectedBackgroundCheck = 'LandlordRejectedBackgroundCheck',
  LandlordIgnoredBackgroundCheck = 'LandlordIgnoredBackgroundCheck',
  RenterSignedLease = 'RenterSignedLease',
  RenterRejectedLease = 'RenterRejectedLease',
  RenterIgnoredLease = 'RenterIgnoredLease',
  LandlordSignedLease = 'LandlordSignedLease'
}

function getCommonStatuses(): Partial<Record<OfferStatusDto, string>> {
  return {
    [OfferStatusDto.Accept]: 'Accepted',
    [OfferStatusDto.Reject]: 'Rejected',
    [OfferStatusDto.Paid]: 'Paid',
    [OfferStatusDto.PendingPayment]: 'Pending payment',
    [OfferStatusDto.Cancel]: 'Canceled',
    [OfferStatusDto.PaymentInProcess]: 'Processing payment',
    [OfferStatusDto.PreApplicationAccepted]: 'Accepted',
    [OfferStatusDto.IncompleteOffer]: 'Incomplete',
    [OfferStatusDto.PaymentFailed]: 'Payment Failed',
    [OfferStatusDto.BackgroundCheckCancelled]: 'Cancelled',
    [OfferStatusDto.BackgroundCheckRequested]: 'Background check requested',
    [OfferStatusDto.BackgroundCheckCompleted]: 'Background check completed',
    [OfferStatusDto.LandlordAcceptedBackgroundCheck]: 'Background check accepted',
    [OfferStatusDto.RenterSignedLease]: 'Signed',
    [OfferStatusDto.RenterRejectedLease]: 'Cancelled',
    [OfferStatusDto.RenterIgnoredLease]: 'Pending lease signing',
    [OfferStatusDto.LandlordSignedLease]: 'All parties signed'
  }
}

export function getLandLordOfferStatuses(): Partial<Record<OfferStatusDto, string>> {
  return {
    ...getCommonStatuses(),
    [OfferStatusDto.PreApplicationPaid]: 'New Offer',
    [OfferStatusDto.CreditCheckCompleted]: 'Credit check completed',
    [OfferStatusDto.PreApplicationRejected]: 'Rejected',
    [OfferStatusDto.DifferentPreApplicationAccepted]: 'New Offer',
    [OfferStatusDto.BackgroundCheckInitiated]: 'Background check initiated',
    [OfferStatusDto.LandlordRejectedBackgroundCheck]: 'Bacground check did not meet',
    [OfferStatusDto.BackgroundCheckIgnored]: 'Accepted',
    [OfferStatusDto.LandlordIgnoredBackgroundCheck]: 'New offer'
    
  }
}

export function getRenterOfferStatuses(): Partial<Record<OfferStatusDto, string>> {
  return {
    ...getCommonStatuses(),
    [OfferStatusDto.PreApplicationPaid]: 'Offer Sent',
    [OfferStatusDto.PreApplicationRejected]: 'Under offer',
    [OfferStatusDto.DifferentPreApplicationAccepted]: 'Under offer',
    [OfferStatusDto.BackgroundCheckInitiated]: 'Background check',
    [OfferStatusDto.LandlordRejectedBackgroundCheck]: 'Property Criteria not met',
    [OfferStatusDto.BackgroundCheckIgnored]: 'Pending background check',
    [OfferStatusDto.LandlordIgnoredBackgroundCheck]: 'Under offer'
  }
}

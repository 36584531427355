import { OfferStatusDto } from 'common/enums/enums';

export const styles = {
  [OfferStatusDto.Accept]: 'bg-[#F1FFF5] text-[#A0D911]',
  [OfferStatusDto.Cancel]: 'bg-[#FEF2F2] text-[#FF385C]',
  // [OfferStatusDto.Expired]: 'bg-[#FFFBEB] text-[#F59E0B]',
  [OfferStatusDto.Reject]: 'bg-[#FEF2F2] text-[#FF385C]',
  [OfferStatusDto.PreApplicationPaid]: 'bg-[#F1FBFF] text-[#66CCFF]',
  [OfferStatusDto.Paid]: 'bg-[#F1FBFF] text-[#0996CE]',
  [OfferStatusDto.PaymentInProcess]: 'bg-[#FFFBEB] text-[#F3C521]',
  [OfferStatusDto.PendingPayment]: 'bg-[#FFFDDD] text-[#CDC000]',
  [OfferStatusDto.PreApplicationAccepted]: 'bg-[#F1FFF5] text-[#A0D911]',
  [OfferStatusDto.IncompleteOffer]: 'bg-[#FFFBEB] text-[#F59E0B]',
  [OfferStatusDto.PaymentFailed]: 'bg-[#FEF2F2] text-[#FF385C]',
  [OfferStatusDto.PreApplicationRejected]: 'bg-[#FEF2F2] text-[#FF385C]',
  [OfferStatusDto.DifferentPreApplicationAccepted]: 'bg-[#FFFBEB] text-[#F3C521]',
  [OfferStatusDto.BackgroundCheckRequested]: 'bg-[#F1FBFF] text-[#0996CE]',
  [OfferStatusDto.BackgroundCheckInitiated]: 'bg-[#F1FFF5] text-[#A0D911]',
  [OfferStatusDto.BackgroundCheckCancelled]: 'bg-[#FEF2F2] text-[#FF385C]',
  [OfferStatusDto.CreditCheckCompleted]: 'bg-[#F1FBFF] text-[#66CCFF]',
  [OfferStatusDto.BackgroundCheckIgnored]: 'bg-[#FFFBEB] text-[#F59E0B]',
  [OfferStatusDto.LandlordAcceptedBackgroundCheck]: 'bg-[#F1FFF5] text-[#A0D911]',
  [OfferStatusDto.LandlordRejectedBackgroundCheck]: 'bg-[#FEF2F2] text-[#FF385C]',
  [OfferStatusDto.LandlordIgnoredBackgroundCheck]: 'bg-[#FFFBEB] text-[#F59E0B]',
  [OfferStatusDto.RenterSignedLease]: 'bg-[#F1FFF5] text-[#A0D911]',
  [OfferStatusDto.RenterRejectedLease]: 'bg-[#FEF2F2] text-[#FF385C]',
  [OfferStatusDto.RenterIgnoredLease]: 'bg-[#FFFBEB] text-[#F59E0B]',
};

export const commonStatusStyle = 'font-medium leading-tight px-[10px] py-[2px] rounded-3xl text-center max-w-[110px]';

export const textStyle = (size: 's' | 'm' | 'l') => {
  switch (size) {
    case 's':
      return 'text-xs';
    case 'm':
      return 'text-sm';
    case 'l':
      return 'text-base';
  }
};
